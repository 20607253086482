import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableHeader = _resolveComponent("TableHeader")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_TableConfig = _resolveComponent("TableConfig")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_TableFooter = _resolveComponent("TableFooter")!
  const _component_TableBody = _resolveComponent("TableBody")!
  const _directive_permission = _resolveDirective("permission")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableBody, null, {
      header: _withCtx(() => [
        _createVNode(_component_TableHeader, {
          "can-collapsed": $setup.likeSearchModel.conditionItems &&
          $setup.likeSearchModel.conditionItems.length !== 0
          ,
          "search-model": $setup.likeSearchModel.conditionItems,
          "default-collapsed-state": false,
          title: "表格查询",
          onDoSearch: $setup.doSearch,
          onResetSearch: $setup.resetSearch
        }, null, 8 /* PROPS */, ["can-collapsed", "search-model", "onResetSearch"])
      ]),
      tableConfig: _withCtx(() => [
        _createVNode(_component_TableConfig, {
          border: $setup.tableConfig.border,
          "onUpdate:border": _cache[0] || (_cache[0] = ($event: any) => (($setup.tableConfig.border) = $event)),
          stripe: $setup.tableConfig.stripe,
          "onUpdate:stripe": _cache[1] || (_cache[1] = ($event: any) => (($setup.tableConfig.stripe) = $event)),
          onRefresh: $setup.doRefresh
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              icon: "Upload",
              onClick: $setup.openPromotionURL
            }, {
              default: _withCtx(() => [
                _createTextVNode("推广开户")
              ]),
              _: 1 /* STABLE */
            }),
            _withDirectives((_openBlock(), _createBlock(_component_el_button, {
              type: "primary",
              icon: "Plus",
              onClick: $setup.addList
            }, {
              default: _withCtx(() => [
                _createTextVNode("手动添加")
              ]),
              _: 1 /* STABLE */
            })), [
              [_directive_permission, '/custom/userList/add']
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["border", "stripe"])
      ]),
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          ref: "tableRef",
          data: $setup.dataList,
          "header-cell-style": $setup.tableConfig.headerCellStyle,
          size: $setup.tableConfig.size,
          stripe: $setup.tableConfig.stripe,
          border: $setup.tableConfig.border,
          height: $setup.tableConfig.height,
          "show-summary": "",
          "summary-method": $setup.getSummaries
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "序号",
              prop: "id",
              width: "100"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "客户姓名",
              prop: "name",
              "min-width": "100"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_el_text, {
                  type: "primary",
                  style: {"cursor":"pointer"},
                  onClick: ($event: any) => ($setup.onUpdateItem(row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(row.name), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "代理名称",
              prop: "proxy_user.name",
              "min-width": "110"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "交易账户",
              prop: "mt_account",
              "min-width": "110"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_el_text, {
                  class: "mx-1",
                  type: "primary",
                  style: {"cursor":"pointer"},
                  onClick: ($event: any) => ($setup.openOrder(row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(row.mt_account), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "本金$",
              prop: "current_deposit",
              "min-width": "110"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "劣后资金$",
              prop: "advance_funds",
              "min-width": "110"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "账户余额",
              prop: "current_balance",
              "min-width": "110"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "账户净值",
              prop: "current_equity",
              "min-width": "110"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "开户状态",
              "min-width": "110",
              prop: "status_label"
            }, {
              default: _withCtx(({ row }) => [
                (row.status == 'submitted')
                  ? (_openBlock(), _createBlock(_component_el_tag, {
                      key: 0,
                      type: "info"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row.status_label), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  : _createCommentVNode("v-if", true),
                (row.status == 'first_pass')
                  ? (_openBlock(), _createBlock(_component_el_tag, {
                      key: 1,
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row.status_label), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  : _createCommentVNode("v-if", true),
                (row.status == 'info_error')
                  ? (_openBlock(), _createBlock(_component_el_tag, {
                      key: 2,
                      type: "danger"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row.status_label), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  : _createCommentVNode("v-if", true),
                (row.status == 'opening')
                  ? (_openBlock(), _createBlock(_component_el_tag, {
                      key: 3,
                      type: "warning"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row.status_label), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  : _createCommentVNode("v-if", true),
                (row.status == 'opened')
                  ? (_openBlock(), _createBlock(_component_el_tag, {
                      key: 4,
                      type: "success"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row.status_label), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              align: "left",
              label: "操作",
              fixed: "right",
              "min-width": "230"
            }, {
              default: _withCtx((scope) => [
                _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                  plain: "",
                  type: "danger",
                  size: "small",
                  onClick: ($event: any) => ($setup.onDeleteItem(scope.row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("删除")
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])), [
                  [_directive_permission, '/custom/userList/del']
                ]),
                _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                  type: "primary",
                  size: "small",
                  plain: "",
                  onClick: ($event: any) => ($setup.changeRebate(scope.row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("修改返佣")
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])), [
                  [_directive_permission, '/custom/userList/editRecord']
                ]),
                (scope.row.status == 'opened')
                  ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      type: "primary",
                      size: "small",
                      plain: "",
                      onClick: ($event: any) => ($setup.changeMoeny(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("出入金")
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])), [
                      [_directive_permission, '/custom/userList/moeny']
                    ])
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data", "header-cell-style", "size", "stripe", "border", "height"])), [
          [_directive_loading, $setup.tableLoading]
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_TableFooter, {
          ref: "tableFooter",
          onRefresh: $setup.doSearch,
          onPageChanged: $setup.doSearch
        }, null, 512 /* NEED_PATCH */)
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" 交易记录 "),
    _createVNode($setup["TranList"], {
      dialogVisible: $setup.dialogVisible,
      userId: $setup.userId,
      onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.dialogVisible = false))
    }, null, 8 /* PROPS */, ["dialogVisible", "userId"]),
    _createCommentVNode(" 手动添加 "),
    _createVNode($setup["UserDet"], {
      ref: "userChild",
      dialogVisible: $setup.showOrder,
      dialogTitle: $setup.dialogTitle,
      isAdd: $setup.isAdd,
      ID: $setup.ID,
      onClose: _cache[3] || (_cache[3] = ($event: any) => ($setup.showOrder = false)),
      onDoSearch: $setup.doSearch
    }, null, 8 /* PROPS */, ["dialogVisible", "dialogTitle", "isAdd", "ID"]),
    _createCommentVNode(" 推广开户 "),
    _createVNode($setup["ProOpen"], {
      dialogVisible: $setup.showOpen,
      onClose: _cache[4] || (_cache[4] = ($event: any) => ($setup.showOpen = false)),
      promotionURL: $setup.promotionURL
    }, null, 8 /* PROPS */, ["dialogVisible", "promotionURL"]),
    _createCommentVNode(" 分成设置 "),
    _createVNode($setup["RebateMode"], {
      ref: "rebateChild",
      ID: $setup.ID,
      dialogVisible: $setup.showModel,
      onClose: _cache[5] || (_cache[5] = ($event: any) => ($setup.showModel = false))
    }, null, 8 /* PROPS */, ["ID", "dialogVisible"]),
    _createCommentVNode(" 出入金 "),
    _createVNode($setup["MoenyList"], {
      dialogVisible: $setup.moneyVisible,
      userId: $setup.userId2,
      onClose: _cache[6] || (_cache[6] = ($event: any) => ($setup.moneyVisible = false))
    }, null, 8 /* PROPS */, ["dialogVisible", "userId"])
  ]))
}